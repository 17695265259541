import { Language, Mail, Phone } from '@mui/icons-material'
import { Grid, Typography } from '@renderer-ui-library/mui'
import { BlockWrapper } from '@renderer-ui-library/scaffolding/BlockWrapper/BlockWrapper'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { BlockProps } from 'blocks/BlockProps'
import classNames from 'classnames'
import { memo, useCallback, useContext } from 'react'
import { ISpecialistColumnsBlockData } from './ISpecialistColumnsBlockData'
import styles from './specialistColumnsBlock.module.scss'

import { BlockTopText, Media } from '@renderer-ui-library/molecules'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { MachineContext } from 'utils/machine/MachineContext'
import { tracker } from 'utils/tracking/tracker'

export const SpecialistColumnsBlock: React.FC<
  BlockProps<ISpecialistColumnsBlockData>
> = memo((props) => {
  const { machine } = useContext(MachineContext)

  const handlePhoneClick = useCallback(
    () =>
      tracker.trackEvent({
        name: 'call-click',
        target_name: 'specialist',
        is_interaction: true,
        machine_group_id: machine?.machineGroupId,
      }),
    [machine]
  )

  return (
    <BlockWrapper blockData={props.data}>
      {(props.data.topText || props.data.title) && (
        <BlockTopText
          title={props.data.title}
          text={props.data.topText}
          highlight={props.data.highlight}
        />
      )}
      <div className={styles.gridSlider}>
        <Splide
          options={{
            type: 'loop',
            heightRatio: 2 / 3,
            pagination: true,
            perPage: 3,
            gap: '2.5rem',
            pauseOnHover: true,
            pauseOnFocus: true,
            height: 'auto',
            autoplay: false,
            rewind: true,
            arrows: false,
            breakpoints: {
              768: {
                perPage: 1,
                gap: '1rem',
                padding: {
                  right: '4rem',
                },
              },
            },
          }}
        >
          {props.data.columns.map((column) => (
            <SplideSlide key={column.id}>
              <Grid
                item
                xs={12}
                md={4}
                key={column.id}
                className={classNames(styles.gridItem, {
                  [styles.highlight]: props.data.highlight,
                })}
              >
                <div className={styles.cardContainer}>
                  <div>
                    <div className={styles.profilePictureContainer}>
                      {column.profilePicture && (
                        <Media
                          media={column.profilePicture}
                          ratio={1}
                          desktopViewportPrecentage={4}
                        />
                      )}
                    </div>

                    <div className={styles.titleContainer}>
                      <Typography variant='h6' textAlign='center'>
                        {column.name}
                      </Typography>
                    </div>
                    <Typography variant='body1' textAlign='center'>
                      {column.title}
                    </Typography>
                    <Typography variant='body1' textAlign='center'>
                      {column.description}
                    </Typography>
                  </div>

                  <div className={styles.contactInfoContainer}>
                    <div className={styles.contactInfoItem}>
                      <div className={styles.contactInfoKey}>
                        <Language className={styles.contactInfoIcon} />
                        <Typography variant='body2'>
                          {column.languages.split(':')[0]}
                        </Typography>
                      </div>
                      <Typography variant='body2'>
                        {column.languages.split(':')[1]}
                      </Typography>
                    </div>
                    <div className={styles.contactInfoItem}>
                      <div
                        className={styles.contactInfoKey}
                        onClick={handlePhoneClick}
                      >
                        <Phone className={styles.contactInfoIcon} />
                        <ReactMarkdown className={styles.serviceNumber}>
                          {
                            props.customerServiceNumbers[
                              column.phoneCountryCode
                            ]
                          }
                        </ReactMarkdown>
                      </div>
                    </div>
                    <div className={styles.contactInfoItem}>
                      <div className={styles.contactInfoKey}>
                        <Mail className={styles.contactInfoIcon} />
                        <Typography variant='body2'>
                          {column.email && column.email.split(':')[0]}
                        </Typography>
                      </div>
                      <a
                        href={`mailto:${
                          column.email && column.email.split(':')[1]
                        }`}
                        className={styles.link}
                      >
                        <Typography variant='body2' align='right'>
                          {column.email && column.email.split(':')[1]}
                        </Typography>
                      </a>
                    </div>
                  </div>
                </div>
              </Grid>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </BlockWrapper>
  )
})

SpecialistColumnsBlock.displayName = 'SpecialistColumnsBlock'
